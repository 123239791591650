body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #000000;
  color: white;
}

.App {
  display: flex;
  height: 100vh;
  background-color: #000000;
}

/* Remove or update the following line */
/* background-image: url('/path-to-high-school-image.jpg'); */

.sidebar {
  width: 250px;
  background-color: #1e1e1e;
  color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.logo {
  font-size: 1.2rem;
  margin-bottom: 20px;
  font-weight: normal;
}

.create-button {
  background-color: #2a2a2a;
  color: white;
  border: none;
  padding: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 0.9rem;
}

.sidebar nav ul {
  list-style-type: none;
  padding: 0;
}

.sidebar nav li {
  margin-bottom: 15px;
  font-size: 0.9rem;
}

.sidebar nav a {
  color: #a0a0a0;
  text-decoration: none;
}

.sidebar nav a:hover {
  color: #ffffff;
}

.nav-section {
  color: #707070;
  font-size: 0.8rem;
  margin-top: 20px;
  margin-bottom: 10px;
}

.user-profile {
  margin-top: auto;
  display: flex;
  align-items: center;
  background-color: #2a2a2a;
  padding: 10px;
  border-radius: 5px;
}

.user-avatar {
  width: 30px;
  height: 30px;
  background-color: #4a4a4a;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-weight: bold;
}

.username {
  font-size: 0.9rem;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #000000;
}

/* Main content styles */
.main-content header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-bar input {
  background-color: #3a3a3a;
  border: none;
  padding: 10px;
  color: white;
  border-radius: 5px;
}

.feeling-section {
  background-image: url('/');
  background-size: cover;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.character-cards, .action-cards {
  display: flex;
  gap: 20px;
  overflow-x: auto;
}

.character-card, .action-card {
  background-color: #2a2a2a;
  padding: 15px;
  border-radius: 10px;
  min-width: 200px;
}

.character-card img, .action-card img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

h2 {
  margin-bottom: 15px;
}

/* Custom Scrollbar Styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
