@import url('https://fonts.googleapis.com/css2?family=Arima:wght@400;700&family=Dancing+Script:wght@400;700&family=Pacifico&family=Lobster&family=Permanent+Marker&family=Indie+Flower&display=swap');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Add any additional custom styles here */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Ensure the top bar doesn't cover content */
.content-below-topbar {
  padding-top: 64px; /* Adjust this value based on your top bar height */
}

.pacifico-regular {
  font-family: "Pacifico", cursive;
  font-weight: 400;
  font-style: normal;
}

.font-arima { font-family: 'Arima', cursive; }

/* Add these styles at the end of the file */

.slick-prev,
.slick-next {
  display: none !important;
}

.carousel-container {
  margin-left: -15px;
  margin-right: -15px;
}

.slick-list {
  margin: 0;
}

.slick-slide > div {
  padding: 0 15px;
}

.slick-track {
  margin-left: 0 !important;
}

.slick-slide {
  float: left !important;
}

.slick-slider {
  display: flex !important;
}

.slick-list {
  min-width: 100%;
}

.carousel-container {
  overflow: hidden;
}

.text-custom-color {
  color: #ff9999; /* Replace with your desired color */
}
